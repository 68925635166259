<template>
    <div style="background-color: #0b0a1f;">
        <div class="week_bg_" style="background-image: url('img/athleticsRank/fireStar/ww_bg.gif');">
            <!-- <div class="week_date_title">{{'活动时间：'+ actDate }}</div> -->
            <div class="st_rule_" style="background-image: url('img/athleticsRank/fireStar/w_rule.png');" @click="goRule"></div>
            <!-- 活动进行中 -->
            <div class="top_mask" style="background-image: url('img/athleticsRank/fireStar/mask.png');"></div>
        </div>
        <div class="week_gift_ display_flex justify-content_flex-justify align-items_center"
                style="background-image: url('img/athleticsRank/fireStar/week_gift_bg.png');">
                <!-- <div v-if="loading" class="w_loading">加载中...</div> -->
            </div>
        <div class="week_rank_father_" style="background-image: url('img/athleticsRank/fireStar/list_bg.png');">
                <div class="nav_container_" style="position:relative;z-index:99999">
                    <div class="week_nav_border_" v-for="(item, index) in nav" :key="index" @click="changeNav(index)">
                        <div class="week_nav_"
                            :style="{ backgroundImage: navCur == index ? `url(img/athleticsRank/fireStar/navActive${index}.png)` : `url(img/athleticsRank/fireStar/nav${index}.png)` }">
                        </div>
                    </div>

                </div>
                <!-- <div v-if="loading" class="w_loading">加载中...</div> -->
                <!-- 缺少v-if，记得补上 -->
                <div class="noData" v-if="noData">
                    <img src="img/noData.png" alt="">
                </div>
                <div class="week_top" v-if="oneData">
                    <!-- 第一名 -->
                    <div style="background-image:url('img/athleticsRank/fireStar/top_one_bg.png');"
                        class="week_one_ display_flex flex-direction_column align-items_center" v-if="oneData">
                        <div class="top-one">
                            <img class="head_one" :src="oneData.head_portrait" alt="">
                            <div class="week_name display_flex justify-content_flex-center align-items_center">
                                <p>{{ oneData.nickname }}</p>
                            </div>
                            <div class="week_id">ID:{{ oneData.uid }}</div>
                            <div class="week_value" style="color: #999999;">胜场
                                <span>{{ oneData.win_num}}</span>
                                <span style="color: #ffffff;margin-left: 10px;">有效</span>
                                <span class="number" style="color: #fff38b;">{{oneData.win_effective_num}}</span>
                            </div>
                            <!-- <div class="top_one_bg" style="background-image:url('img/athleticsRank/top_one_bg.png');"></div> -->
                        </div>

                    </div>
                    <!-- 第二名 -->
                    <div style="background-image:url('img/athleticsRank/fireStar/top_two_bg.png');"
                        class="week_two_ display_flex flex-direction_column align-items_center" v-if="twoData">
                        <div class="top-two">
                            <img class="head_two" :src="twoData.head_portrait" alt="">
                            <div class="week_nickname">{{ twoData.nickname }}</div>
                            <div class="week_id">ID:{{ twoData.uid }}</div>
                            <div class="week_value" style="color: #999999;">胜场
                                <span>{{ twoData.win_num}}</span>
                                <span style="color: #ffffff;margin-left: 10px;">有效</span>
                                <span class="number" style="color: #fff38b;">{{twoData.win_effective_num}}</span>
                            </div>
            
                        </div>

                        <!-- <div class="top_two_bg" style="background-image:url('img/athleticsRank/top_two_bg.png');"></div> -->
                    </div>
                    <!-- 第三名 -->
                    <div style="background-image:url('img/athleticsRank/fireStar/top_three_bg.png');"
                        class="week_third_ display_flex flex-direction_column align-items_center" v-if="thirdData">
                        <div class="top-three">
                            <img class="head_three" :src="thirdData.head_portrait" alt="">
                            <div class="week_nickname">{{ thirdData.nickname }}</div>
                            <div class="week_id">ID:{{ thirdData.uid }}</div>
                            <div class="week_value" style="color: #999999;">胜场
                                <span>{{ thirdData.win_num}}</span>
                                <span style="color: #ffffff;margin-left: 10px;">有效</span>
                                <span class="number" style="color: #fff38b;">{{thirdData.win_effective_num}}</span>
                            </div>
                        </div>

                        <!-- <div class="top_three_bg" style="background-image:url('img/athleticsRank/top_three_bg.png');"></div> -->
                    </div>
                </div>
                <!-- 第四名往后 -->
                <div class="week_listMg">
                    <div class="week_view_ display_flex justify-content_flex-justify align-items_center"
                        v-for="(item, index) in list" :key="index"
                        style="background-image: url('img/athleticsRank/fireStar/view_bg.png');"
                        >
                        <div class="display_flex align-items_center">
                            <div class="week_three_">{{ index + 4 }}</div>
                            <img class="week_head_" :src="item.head_portrait" alt="">
                            <div class="week_people">
                                <p>{{ item.nickname }}</p>
                                <span>ID:{{ item.uid }}</span>
                            </div>
                        </div>
                        <div class="week_value_" style="color: #999999;">胜场
                                <span style="font-size:12px;">{{ item.win_num}}</span>
                                <span style="color: #ffffff;margin-left: 10px;">有效</span>
                                <span class="number" style="color: #fff38b;">{{item.win_effective_num}}</span>
                        </div>
                    </div>
                </div>
              
                <!-- 自己排行 -->
                <!-- 无数据 -->
                <!-- <div class="w_noData" v-if="noData">
                    <img src="img/athleticsRank/none.png" alt="">
                </div> -->
       
            <div class="bottom_tips">本活动最终解释权归多乐所有</div>
        </div>

      
        <!-- <div class="week_beself" style="background-image: url(img/athleticsRank/week_bottom.png);"></div> -->


    </div>
</template>
  
<script>
import "@/assets/css/base.css"
import "@/assets/css/athletics.css"
import { gameFireRankList } from "@/api/ranking";

export default {
    name: 'athleticsRank',
    data() {
        return {
            nav: [1,2],
            navCur: 1,
            list: [],
            giftInfo: [],
            loading: true,
            noData: false,
            oneData: null,
            twoData: null,
            thirdData: null
        }
    },
    created() {
        let access_token = this.$route.query.access_token
        localStorage.setItem('access_token', access_token)
        this.getData()
    },
    methods: {
        getData() {
            let week_type = this.navCur==0?2:1
            gameFireRankList({week_type}).then(response => {
                this.loading = false
                let [oneData = null, twoData = null, thirdData = null, ...list] = response.data
                this.oneData = oneData
                this.twoData = twoData
                this.thirdData = thirdData
                this.list = list
                if (response.data.length == 0) {
                    this.noData = true
                } else {
                    this.noData = false
                }
            },
                error => {
                    this.loading = false
                    this.noData = true
                    this.$toast.fail(error.msg)
                }).catch((error) => {
                    this.loading = false
                    this.noData = true
                    this.$toast.fail(error.msg)
                })
        },
        changeNav(e) {
            this.navCur = e
            this.getData()
        },
        goRule() {
            this.$router.push({ path: '/rank/fireStarRule', query: { date: this.actDate } })
        },
        goBack() {
            this.$router.go(-1)
        }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
  